import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Details from "../../components/DetailsComponent";
import { Image } from "react-bootstrap";

export default function ArtTherapyWorkshop() {

  const textAT = "Terapia prin arta imbina procesul creatiei cu latura terapeutica, arta devenind astfel o forma de comunicare si prelucrare a gandurilor, emotiilor, starilor noastre. Unele dificultati emotionale pe care copiii si adolescentii la resimt ajung sa fie exprimate mai usor prin pictura, aceasta avand astfel un rol in procesul de vindecare. Terapeutul sprijina exprimarea creativa a dificultatilor emotionale prin prezenta sa, prin intrebarile sale, prin explorarea produsului finit. Terapia prin arta ajuta copilul si adolescentul sa constientizeze mai bine ce simte si reprezinta o cale de promovare a sanatatii mintale."
  const textAT2 = "Cu mult drag, anuntam o serie de ateliere de art-terapie sustinute in perioada februarie-martie de colega noastra, psihopedagog Alexandra Campeanu la sediul asociatiei noastre. Sub îndrumarea si coordonarea ei, fiecare intalnire va pleca de la o tema de dezvoltare personala, se va concretiza in tablourile realizate de copiii si adolescenti pentru ca, la final, sa ne intalnim cu totii la un eveniment-expozitie cu ocazia zilei de 2 aprilie, ziua internationala de constientizare a autismului."

  return (
    <>

    <Helmet>
        <title>Ateliere de art-terapie</title>
        <meta
          name="description"
          content="Grupul are ca obiective dezvoltarea abilitatilor de comunicare si socializare, precum si generalizarea achizitiilor de pe celelalte arii de dezvoltare. Grupul se va desfasura in cadrul Asociatiei PsiNapsis din Sibiu si va fi sustinut de un terapeut inregistrat in registrul TSA de la COPSI. "
        />
        <link rel="canonical" href="https://www.psinapsis.ro/events/arttherapyevent" />
      </Helmet>

    <Row className="p-0 m-0">
      <Col className="d-none d-xl-block"></Col>
      <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">

        <Title title="Ateliere de art-terapie" />
        <Details normalText={textAT} /> 
        <div className="text-center d-md-none mb-3">
            <Image
              className="photoArticol"
              src="/images/arta.png"
              alt="Ateliere de art-terapie"
            />
          </div>
        <Details normalText={textAT2} />

<div>
            <span className="ms-5 me-2">
              <Image src="/icons/calendar_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">februarie-martie 2025</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/hour_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">Vineri, 14:00 - 15:30</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/location_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">Asociația PsiNapsis</span>
          </div>

          <div className="text-center d-none d-md-block">
            <Image
              className="photoArticol"
              src="/images/arta.png"
              alt="Ateliere de art-terapie"
            />
          </div>

      </Col>
      <Col className="d-none d-xl-block"></Col>
    </Row>
    </>
  );
}
