import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Details from "../../components/DetailsComponent";
import { Helmet } from "react-helmet-async";

export default function CognitiveImpairement() {
  const desc1 =
    "presupune o degradare treptata a functiilor cognitive, in special memoria, gandirea si atentia. Acest declin poate fi rezultatul diferitor factori, cum ar fi: imbatranirea, bolile neurodegenerative sau leziuni cerebrale.";

  const desc2 =
    "Pe masura ce declinul progreseaza, sarcinile zilnice devin din ce in ce mai dificile, provocand frustrare, anxietate si o pierdere a independentei. Activitatile de baza precum gestionarea finantelor, amintirea evenimentelor importante sau chiar recunoasterea fetelor familiare pot deveni descurajante.";

  const desc3 =
    "Membrii familii si prietenii sunt martori la tranformarea treptata a persoanei iubite, adesea confruntandu-se cu sentimente de tristete, neputinta si pierdere. Astfel, rolurile se pot inversa, povara ingrijirii trecand de la persoana afectata la membrii familiei acesteia.";

  const desc4 =
    "Daca simti ca tu sau cineva drag pare sa se confrunte cu dificultatile mentionate anterior, noi putem sa ajutam printr-o evaluare cu instrumentul MMSE-2 si recomandari de interventie, cat si materiale educative.";

  const desc5 =
    "Recomandam filmul „Still Alice” („Inca Alice”), care reda povestea unei profesoare universitare ce este diagnosticata cu o forma agresiva si timpurie de Alzheimer.";

  return (
    <>
      <Helmet>
        <title>Deteriorarea cognitiva</title>
        <meta
          name="description"
          content="Deteriorarea cognitiva presupune o degradare treptata a functiilor cognitive, in special memoria, gandirea si atentia. Pentru evaluare se foloseste instrumentul MMSE-2. Text redactat de Calinescu Diana, psiholog psihopedagogie speciala si logoped in cadrul Asociatiei PsiNapsis din Sibiu."
        />
        <link
          rel="canonical"
          href="https://www.psinapsis.ro/articles/cognitiveimpairementarticle"
        />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title="Deteriorarea cognitiva" />

          <Details highlightText="Deteriorarea cognitiva " normalText={desc1} />
          <Details normalText={desc2} />

          <Details normalText={desc3} />

          <Details highlightText="Ce putem oferi noi? " />

          <Details normalText={desc4} />

          <Details highlightText="Recomandare de film " />

          <Details normalText={desc5} />

          <div className="text-center">
            <Image
              className="photoArticol "
              src="/images/detcog4.jpg"
              alt="Deteriorarea cognitiva, Calinescu Diana, Asociatia PsiNapsis"
            />
          </div>

          <div className="pt-4">
            <Details normalText="Text redactat de: Diana Calinescu - Psiholog psihopedagogie speciala, Logoped " />
          </div>
          <Details normalText="18 octombrie 2023" />
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
