import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Title from "../components/TitleComponent";
import Details from "../components/DetailsComponent";
import Objectives from "../components/ObjectivesComponent";
import DetailsWImage from "../components/DetailsWImageComponent";
import { Helmet } from "react-helmet-async";

export default function DonationPage() {
  const objectives = [
    "Sustinerea terapiilor copiilor cu dizabilitati;",
    "Cresterea constientizarii comunitatii cu privire la copiii si familiile in situatii de risc/excluziune;",
    "Informarea, prin intermediul workshop-urilor, despre modalitati de crestere a calitatii vietii in randul adultilor;",
    "Sprijinire, informare si consiliere vocationala a tinerilor absolventi;",
    "Dezvoltarea abilitatilor de comunicare, socializare si relationare a copiilor si adolescentilor;",
    "Consiliere suportiva a parintilor/ grupuri de suport;",
    "Organizarea unor activitati de tip respiro (baby-parking) pentru copiii cu dizabilitati;",
  ];

  const desc =
    "Descarca formularul, completeaza-l la sectiunile date de identificare contribuabil si semnatura contribuabil. Poti trimite formularul pe e-mail la adresa asociatiapsinapsis@gmail.com sau sa il lasi completat in original la sediul asociatiei noastre si noi il depunem la ANAF pentru tine. Poţi face redirecţionarea celor 3,5% pentru o perioada de 2 ani. Anul viitor ANAF va redirecţiona automat suma catre Asociatia PsiNapsis.";

  const desc2 =
    "Firmele pot ajuta prin redirectionarea unui procent de 20% din impozitul companiei. Redirectionarea nu presupune costuri suplimentare pentru companie si se face printr-un contract de sponsorizare care poate fi completat si trimis pe adresa de e-mail asociatiapsinapsis@gmail.com. Plata trebuie efectuata pana la 31 decembrie, pentru a se considera parte din anul fiscal in curs, altfel va fi considerata sponsorizare aferenta anului urmator.";

  return (
    <>
      <Helmet>
        <title>Doneaza</title>
        <meta
          name="description"
          content="Sprijina cauzele noastre si doneaza o sedinta de terapie prin transfer bancar. Redirectioneaza 3.5% din impozitul pe venit sau 20% din impozit in cazul companiilor."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/donation" />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title="Doneaza" />
          <Details normalText="Sumele obtinute din donatii vor fi folosite pentru implementarea de proiecte ce vizeaza:" />
          <div className="objList">
            <Objectives
              talign="objective-align-justify"
              objectives={objectives}
            />
          </div>
          <Details normalText="Sprijina cauzele noastre si urmareste-ne progresul pe site si in social media." />

          <Title title="Doneaza o ședinta de terapie x 100 RON" />

          <div className="text-center fs-6 mb-3 mx-4">
            <DetailsWImage />
          </div>

          <Title title="Redirectioneaza 3,5% din impozitul pe venit" />
          <Details normalText={desc} />
          <div className="text-center">
            <Button
              className="downloadBtn"
              href="/files/Form 230 PsiNapsis.pdf"
              target="_blank"
              download
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-cloud-arrow-down-fill downloadIcon"
                viewBox="0 0 16 16"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
              </svg>
              Descarca formularul 230
            </Button>
          </div>

          <Title title="Redirecționeaza 20% din impozitul companiei" />
          <Details normalText={desc2} />
          <div className="text-center pb-5">
            <Button
              className="downloadBtn"
              href="/files/Contract sponsorizare PsiNapsis.pdf"
              target="_blank"
              download
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-cloud-arrow-down-fill downloadIcon"
                viewBox="0 0 16 16"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
              </svg>
              Descarca contractul de sponsorizare
            </Button>
          </div>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
